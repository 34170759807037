var render = function () {
  var _vm$myRanking, _vm$myRanking$ranking, _vm$myRanking2, _vm$myRanking2$rankin;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "user-profile"
    }
  }, [_c('b-navbar', {
    staticClass: "header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
  }, [_c('navbar')], 1), _c('b-row', {
    staticClass: "justify-content-center"
  }, [!_vm.isSingleRankingResult ? _c('b-col', {
    staticClass: "my-2",
    attrs: {
      "sm": "11",
      "md": "7"
    }
  }, [_c('section', [_c('strong', {
    staticClass: "d-block",
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v("Ranking per Sub Kategori")]), _c('b-form-select', {
    attrs: {
      "options": _vm.subcats
    },
    model: {
      value: _vm.selectedSubcat,
      callback: function callback($$v) {
        _vm.selectedSubcat = $$v;
      },
      expression: "selectedSubcat"
    }
  })], 1)]) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }), _c('b-col', {
    attrs: {
      "sm": "10",
      "md": "4",
      "lg": "3"
    }
  }, [_vm.myRanking ? _c('b-card', {
    staticClass: "bg-primary mt-3"
  }, [_c('section', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('div', {
    staticStyle: {
      "margin-right": "10px"
    }
  }, [_c('feather-icon', {
    staticClass: "text-light",
    attrs: {
      "size": "60",
      "icon": "BarChart2Icon"
    }
  })], 1), _c('div', [_c('h4', {
    staticClass: "media-heading text-white"
  }, [_c('b', [_vm._v(" Rank Saya")])]), _c('span', {
    staticClass: "text-white"
  }, [_c('b', [_vm._v(" " + _vm._s((_vm$myRanking = _vm.myRanking) === null || _vm$myRanking === void 0 ? void 0 : (_vm$myRanking$ranking = _vm$myRanking.ranking) === null || _vm$myRanking$ranking === void 0 ? void 0 : _vm$myRanking$ranking.ke))]), _vm._v("/" + _vm._s((_vm$myRanking2 = _vm.myRanking) === null || _vm$myRanking2 === void 0 ? void 0 : (_vm$myRanking2$rankin = _vm$myRanking2.ranking) === null || _vm$myRanking2$rankin === void 0 ? void 0 : _vm$myRanking2$rankin.dari) + " ")])])])]) : !_vm.myRanking && !_vm.isSingleRankingResult ? _c('section', [_c('b-alert', {
    staticClass: "p-1",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('strong', [_vm._v("Harap pilih Sub Kategori untuk melihat hasil.")])])], 1) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }), _vm.paketRanking ? _c('b-col', {
    attrs: {
      "sm": "11",
      "md": "11",
      "lg": "8"
    }
  }, [_c('section', {
    staticClass: "mb-3"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', {
    staticStyle: {
      "overflow": "auto"
    }
  }, [_c('h4', {
    staticClass: "text-center"
  }, [_vm._v("Top 10 Rank")]), _c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    attrs: {
      "md": "4",
      "sm": "4"
    }
  }, [_vm.paketRanking[0].selected_majors ? _c('label', [_vm._v("Filter Jurusan")]) : _vm._e(), _c('v-select', {
    staticClass: "select-size-sm w-full",
    attrs: {
      "label": "text",
      "clearable": true,
      "options": _vm.optJurusan
    },
    on: {
      "input": function input($event) {
        return _vm.getRanking();
      }
    },
    model: {
      value: _vm.filterJurusan,
      callback: function callback($$v) {
        _vm.filterJurusan = $$v;
      },
      expression: "filterJurusan"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "2",
      "sm": "2"
    }
  }), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Filter Berdasarkan Sekolah Anda?",
      "label-for": "filter"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    on: {
      "input": function input($event) {
        return _vm.getRanking();
      }
    },
    model: {
      value: _vm.filterSekolah,
      callback: function callback($$v) {
        _vm.filterSekolah = $$v;
      },
      expression: "filterSekolah"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Filter Berdasarkan Provinsi Anda?",
      "label-for": "filter"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    on: {
      "input": function input($event) {
        return _vm.getRanking();
      }
    },
    model: {
      value: _vm.filterProvinsi,
      callback: function callback($$v) {
        _vm.filterProvinsi = $$v;
      },
      expression: "filterProvinsi"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-bordered responsive"
  }, [_c('tr', {
    staticClass: "bg-primary text-white",
    attrs: {
      "variant": "primary"
    }
  }, [_c('th', {
    staticStyle: {
      "width": "5%"
    }
  }, [_vm._v("Peringkat")]), _c('th', [_vm._v("Nama")]), _vm.isSingleRankingResult ? _c('th', [_vm._v("Target Jurusan 1")]) : _vm._e(), _vm.isSingleRankingResult ? _c('th', [_vm._v("Target Jurusan 2")]) : _vm._e(), _c('th', [_vm._v("Skor")])]), _vm._l(_vm.paketRanking, function (item, i) {
    var _item$hasil_rasionali, _item$hasil_rasionali2, _item$hasil_rasionali3, _item$hasil_rasionali4, _item$hasil_rasionali5, _item$hasil_rasionali6, _item$hasil_rasionali7, _item$hasil_rasionali8;

    return _c('tr', {
      key: i
    }, [_c('td', {
      staticStyle: {
        "width": "5%"
      }
    }, [_vm._v(" " + _vm._s(item.ranking.ke ? item.ranking.ke : item.ranking) + " ")]), _c('td', [_vm._v(_vm._s(item.user_name ? item.user_name : item.name))]), _vm.isSingleRankingResult ? _c('td', [_vm._v(" " + _vm._s(item.hasil_rasionalisasi && item.hasil_rasionalisasi[0] ? ((_item$hasil_rasionali = item.hasil_rasionalisasi[0]) === null || _item$hasil_rasionali === void 0 ? void 0 : (_item$hasil_rasionali2 = _item$hasil_rasionali.university_info) === null || _item$hasil_rasionali2 === void 0 ? void 0 : _item$hasil_rasionali2.major_name) + " - " + ((_item$hasil_rasionali3 = item.hasil_rasionalisasi[0]) === null || _item$hasil_rasionali3 === void 0 ? void 0 : (_item$hasil_rasionali4 = _item$hasil_rasionali3.university_info) === null || _item$hasil_rasionali4 === void 0 ? void 0 : _item$hasil_rasionali4.name) : "-") + " ")]) : _vm._e(), _vm.isSingleRankingResult ? _c('td', [_vm._v(" " + _vm._s(item.hasil_rasionalisasi && item.hasil_rasionalisasi[1] ? ((_item$hasil_rasionali5 = item.hasil_rasionalisasi[1]) === null || _item$hasil_rasionali5 === void 0 ? void 0 : (_item$hasil_rasionali6 = _item$hasil_rasionali5.university_info) === null || _item$hasil_rasionali6 === void 0 ? void 0 : _item$hasil_rasionali6.major_name) + " - " + ((_item$hasil_rasionali7 = item.hasil_rasionalisasi[1]) === null || _item$hasil_rasionali7 === void 0 ? void 0 : (_item$hasil_rasionali8 = _item$hasil_rasionali7.university_info) === null || _item$hasil_rasionali8 === void 0 ? void 0 : _item$hasil_rasionali8.name) : "-") + " ")]) : _vm._e(), _c('td', [_vm._v(_vm._s(item.jumlah_nilai))])]);
  })], 2)])], 1)], 1), _c('br'), _c('br')], 1)], 1)]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }