<template>
  <div id="user-profile">
    <b-navbar
      class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
    >
      <navbar />
    </b-navbar>
    <b-row class="justify-content-center">
      <b-col sm="11" md="7" v-if="!isSingleRankingResult" class="my-2">
        <section>
          <strong class="d-block" style="margin-bottom: 10px"
            >Ranking per Sub Kategori</strong
          >
          <b-form-select v-model="selectedSubcat" :options="subcats" />
        </section>
      </b-col>
      <b-col cols="12"></b-col>
      <b-col sm="10" md="4" lg="3">
        <b-card class="bg-primary mt-3" v-if="myRanking">
          <section class="d-flex justify-content-center align-items-center">
            <div style="margin-right: 10px">
              <feather-icon size="60" class="text-light" icon="BarChart2Icon" />
            </div>
            <div>
              <h4 class="media-heading text-white">
                <b> Rank Saya</b>
              </h4>
              <span class="text-white">
                <b> {{ myRanking?.ranking?.ke }}</b
                >/{{ myRanking?.ranking?.dari }}
              </span>
            </div>
          </section>
        </b-card>
        <section v-else-if="!myRanking && !isSingleRankingResult">
          <b-alert show variant="warning" class="p-1">
            <strong>Harap pilih Sub Kategori untuk melihat hasil.</strong>
          </b-alert>
        </section>
      </b-col>
      <b-col cols="12"></b-col>
      <b-col sm="11" md="11" lg="8" v-if="paketRanking">
        <section class="mb-3">
          <b-overlay :show="loading">
            <b-card style="overflow: auto">
              <h4 class="text-center">Top 10 Rank</h4>

              <b-row class="mt-4">
                <b-col md="4" sm="4" class="">
                  <label v-if="paketRanking[0].selected_majors"
                    >Filter Jurusan</label
                  >
                  <v-select
                    class="select-size-sm w-full"
                    label="text"
                    v-model="filterJurusan"
                    @input="getRanking()"
                    :clearable="true"
                    :options="optJurusan"
                  />
                </b-col>
                <b-col md="2" sm="2" class=""> </b-col>
                <b-col md="3" sm="3" class="">
                  <b-form-group
                    label="Filter Berdasarkan Sekolah Anda?"
                    label-for="filter"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        v-model="filterSekolah"
                        @input="getRanking()"
                        switch
                        inline
                      />
                    </b-form-group>
                  </b-form-group>
                </b-col>
                <b-col md="3" sm="3" class="">
                  <b-form-group
                    label="Filter Berdasarkan Provinsi Anda?"
                    label-for="filter"
                  >
                    <b-form-group>
                      <b-form-checkbox
                        v-model="filterProvinsi"
                        @input="getRanking()"
                        switch
                        inline
                      />
                    </b-form-group>
                  </b-form-group>
                </b-col>
                <b-col md="12" class="my-1">
                  <table class="table table-bordered responsive">
                    <tr class="bg-primary text-white" variant="primary">
                      <th style="width: 5%">Peringkat</th>
                      <th>Nama</th>
                      <th v-if="isSingleRankingResult">Target Jurusan 1</th>
                      <th v-if="isSingleRankingResult">Target Jurusan 2</th>
                      <th>Skor</th>
                    </tr>
                    <tr v-for="(item, i) in paketRanking" :key="i">
                      <td style="width: 5%">
                        {{ item.ranking.ke ? item.ranking.ke : item.ranking }}
                      </td>
                      <td>{{ item.user_name ? item.user_name : item.name }}</td>
                      <td v-if="isSingleRankingResult">
                        {{
                          item.hasil_rasionalisasi &&
                          item.hasil_rasionalisasi[0]
                            ? item.hasil_rasionalisasi[0]?.university_info
                                ?.major_name +
                              " - " +
                              item.hasil_rasionalisasi[0]?.university_info?.name
                            : "-"
                        }}
                      </td>
                      <td v-if="isSingleRankingResult">
                        {{
                          item.hasil_rasionalisasi &&
                          item.hasil_rasionalisasi[1]
                            ? item.hasil_rasionalisasi[1]?.university_info
                                ?.major_name +
                              " - " +
                              item.hasil_rasionalisasi[1]?.university_info?.name
                            : "-"
                        }}
                      </td>
                      <td>{{ item.jumlah_nilai }}</td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
            </b-card>
            <br />
            <br />
          </b-overlay>
        </section>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BOverlay,
  BMedia,
  BRow,
  BCol,
  BContainer,
  BCard,
  BCardBody,
  BCardFooter,
  BBadge,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormTextarea,
  BTableSimple,
  BTbody,
  BThead,
  BTfoot,
  BTr,
  BTd,
  BAlert,
  BTh,
} from "bootstrap-vue";

import Navbar from "./components/Navbar.vue";
import vSelect from "vue-select";
/* eslint-disable global-require */
export default {
  components: {
    vSelect,
    BOverlay,
    BMedia,
    BRow,
    BCol,
    BContainer,
    BCard,
    BCardBody,
    BCardFooter,
    BBadge,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    BTableSimple,
    BTbody,
    BThead,
    BTfoot,
    BTr,
    BTd,
    BAlert,
    BTh,
    Navbar,
  },

  data() {
    return {
      selectedSubcat: null,
      subcats: [],
      filterJurusan: null,
      filterSekolah: false,
      filterProvinsi: false,
      paketRanking: null,
      optJurusan: null,
      myRanking: null,
      loading: false,
      totalRows: 0,
      isSingleRankingResult: true,
    };
  },

  computed: {
    paketDetail() {
      return this.$store.state.paketTryout.detail;
    },
  },
  watch: {
    selectedSubcat(val) {
      if (val) {
        const dataRanking = this.subcats.find((item) => item.subcat_id == val);
        if (dataRanking) {
          this.paketRanking = dataRanking?.data;
          this.paketRanking = this.paketRanking.map((result) => {
            result.jumlah_nilai = result.nilai;
            return result;
          });
          this.myRanking = this.paketRanking.find(
            (result) => result.user_id == this.user.id
          );
        }
      }
    },
  },

  methods: {
    async getRanking() {
      this.loading = true;
      const payload = {
        paket_id: this.$route.query.p,
        school: this.filterSekolah ? this.user.detail.school_origin : null,
        provinsi:
          this.filterProvinsi && this.user.detail.school_origin_info
            ? this.user.detail.school_origin_info.provinsi
            : null,
        major_id: this.filterJurusan ? this.filterJurusan.value : null,
        jadwal_id: this.$route.query.j,
      };
      this.$store
        .dispatch("ujian/indexRanking", payload)
        .then((response) => {
          this.loading = false;
          this.isSingleRankingResult = Array.isArray(response.data)
            ? false
            : true;

          if (this.isSingleRankingResult) {
            let paketRanking = response.data.data;
            this.paketRanking = paketRanking;
            if (this.myRanking == null) {
              let myRanking = paketRanking.find((res) => {
                return res.user_id == this.user.id;
              });
              this.myRanking = myRanking;
              this.totalRows = this.paketRanking.length;
            }
            if (this.optJurusan == null) {
              let optJurusan = [];
              this.paketRanking.forEach((el) => {
                el.selected_majors.forEach((major) => {
                  const existMajor = optJurusan.find(
                    (jurusan) => jurusan.value == major.id
                  );
                  if (!existMajor) {
                    optJurusan.push({
                      value: major ? major.id : "-",
                      text: major ? major.name : "-",
                    });
                  }
                });
              });
              this.optJurusan = optJurusan;
            }
          } else {
            // get subcat
            let resultRanking = response.data;
            this.subcats = resultRanking.map((ranking) => ({
              value: ranking.subcat_id,
              text: ranking.subcat_name,
              ...ranking,
            }));
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
  },

  created() {
    if (!this.$route.query?.p || !this.$route.query?.j) {
      this.$router.go(-1);
    }
    this.getRanking();
  },
};
</script>
